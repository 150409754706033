
import { defineComponent, ref, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'Download',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: [],
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()
    const conf: any = inject('$conf')
    return {
      route,
      router,
      store,
      mixins,
      conf,
    }
  },
  data(): {
    // editable: any
    // form: any
    message: any
  } {
    return {
      // editable: null,
      // form: {
      //   file: null,
      //   alias: null,
      // },
      message: '',
    }
  },
  computed: {
    //
  },
  watch: {
    //
  },
  beforeMount() {
    this.message = '登場人物が指定されている場合は、指定された人物が出演しているカットのみを出力します'
  },
  methods: {
    download() {
      this.$emit('download')
    },
    close() {
      this.$emit('close')
    },
  },
})
