<template>
  <div class="dialog" @mousedown.self="close">
    <div class="wrapper">
      <div class="dialog-header">
        <p class="title">Premiere連携ファイルの出力</p>
        <div class="button" @click="close"><i class="material-icons">close</i></div>
      </div>
      <div class="dialog-content">
        <p class="message">{{ message }}</p>
      </div>
      <div class="dialog-footer">
        <div class="button cancel" @click="close">キャンセル</div>
        <div class="button" @click="download">出力</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'Download',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: [],
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()
    const conf: any = inject('$conf')
    return {
      route,
      router,
      store,
      mixins,
      conf,
    }
  },
  data(): {
    // editable: any
    // form: any
    message: any
  } {
    return {
      // editable: null,
      // form: {
      //   file: null,
      //   alias: null,
      // },
      message: '',
    }
  },
  computed: {
    //
  },
  watch: {
    //
  },
  beforeMount() {
    this.message = '登場人物が指定されている場合は、指定された人物が出演しているカットのみを出力します'
  },
  methods: {
    download() {
      this.$emit('download')
    },
    close() {
      this.$emit('close')
    },
  },
})
</script>

<style lang="stylus" scoped>
.dialog
  .wrapper
    width 800px
    height auto

  &-content
    display grid
    grid-template auto 1fr / auto
    grid-gap $gap
    // border-top 1px solid #E6E6E6
    // border-bottom 1px solid #E6E6E6
    // padding $gap 0
    .box:nth-child(1)
      grid-row span 2
      grid-column 1
//
</style>
