
import { defineComponent, ref, toRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'ProjectShare',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup(props) {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()

    // const queries = mixins.getQuery()
    // const project_id: any = route.params.project_id
    const currentProject = {
      project_id: parseInt(props.data.project_id),
    }

    return {
      route,
      router,
      store,
      mixins,
      // queries,
      currentProject,
    }
  },
  data(): {
    editable: any
    share_members: any
    add: any
    context: any
    composing: any
  } {
    return {
      editable: null,
      share_members: null,
      add: null,
      context: null,
      composing: false,
    }
  },
  computed: {
    share_members_(): any {
      const list = Object.assign([], this.share_members)
      list.sort((a: any, b: any) => {
        return a['email'].localeCompare(b['email'], 'ja')
      })
      return list
    }
  },
  watch: {
    //
  },
  async beforeMount() {
    const project = await this.mixins.getProject(this.currentProject)
    this.editable = project
    this.share_members = Object.assign([], project.share_members)
    this.share_members.forEach((member: any) => {
      member.owner = false
    })
    this.share_members.push({
      email: project.user_id,
      user_type: 1,
      owner: true,
    })

    this.share_members.sort((a: any, b: any) => {
      return a['email'].localeCompare(b['email'], 'ja')
    })
  },
  methods: {
    openContext(e: any, item: any) {
      const rect = e.target.closest('.role').getBoundingClientRect()
      console.log(rect)
      this.context = {
        item: item,
        position: rect,
      }
      window.addEventListener('click', this.closeContext)
    },
    closeContext() {
      this.context = null
      window.removeEventListener('click', this.closeContext)
    },
    close() {
      this.$emit('close')
    },
    contextStyle() {
      const x = this.context ? this.context.position.x : 0
      const y = this.context ? this.context.position.y + this.context.position.height : 0
      console.log(x, y)
      return { left: `${x}px`, top: `${y}px` }
    },
    getRoleString(user: any) {
      const string = ['管理者', '編集者', '閲覧者']
      if (user.owner) {
        return '作成者'
      } else {
        return user.user_type === -1 ? '削除' : string[user.user_type]
      }
    },
    updateRole(index: any) {
      this.context.item.user_type = index
    },
    addUser() {
      if (this.composing) { return }

      const user_id = this.add
      let isType: any = false
      let isExist: any = false

      // 半角英数かどうか
      if (user_id.match(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/)) {
        isType = true
      }
      // 英数だった場合
      // 重複チェック
      isExist = this.share_members.every((member: any) => member.email !== user_id)

      // 判定OKなら追加
      if (isType && isExist) {
        this.share_members.push({
          email: user_id,
          user_type: 1,
          owner: false,
        })
      } else {
        let error = ''
        if (!isType) {
          error += '無効なアドレスです\n'
        }
        if (!isExist) {
          error += '既に登録されているアドレスです\n'
        }
        alert(error)
      }
      // 入力欄をクリア
      this.add = null
    },
    async update() {
      // 入力中のものがある
      if (this.add && !confirm('入力中のデータがありますがよろしいですか？')) { return }
      // 変数
      const members = this.share_members
        .filter((member: any) => member.email !== this.editable.user_id && member.user_type !== -1)
        .map((member: any) => {
          return {
            email: member.email,
            user_type: member.user_type,
          }
        })
      const data = {
        project_id: this.editable.project_id,
        members: members,
      }
      const res = await this.mixins.updatesharepro(data)
      if (res) {
        console.log('通ったかのチェック', res)
        let error: any = ''
        res.outputs[0]?.results?.forEach((result: any, i: number) => {
          if (!result) {
            const shares = this.share_members.filter((member: any) => !member.owner)
            error += `${shares[i].email}は無効なアドレスです\n`
          }
        })
        if (error) {
          alert(error)
        } else {
          alert('登録に成功しました')
          this.$emit('update')
        }
      } else {
        console.log('エラー')
      }
    },
  },
})
