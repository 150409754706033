<template>
  <div class="dialog" @mousedown.self="close">
    <div class="wrapper">
      <div class="dialog-header">
        <p class="title">ユーザーと共有</p>
        <div class="button" @click="close"><i class="material-icons">close</i></div>
      </div>
      <div class="dialog-content">
        <div class="input text">
          <input type="text" v-model="add" placeholder="アドレスを追加" @keydown.enter="addUser" @compositionstart="composing = true" @compositionend="composing = false">
          <span class="button" @click="addUser"><i class="material-icons">add_circle</i></span>
        </div>
        <div class="list">
          <div class="item" v-for="(item, i) in share_members_" :key="i" :class="{owner: item.owner, del: item.user_type === -1}">
            <div class="email">{{ item.email }}</div>
            <div class="role" @click.stop="openContext($event, item)">
              <span class="text">{{ getRoleString(item) }}</span>
              <span class="icon"><i class="material-icons">expand_more</i></span>
            </div>
          </div>
        </div>
        <div class="context" v-if="context" :style="contextStyle()">
          <div class="row" @click="updateRole(2)"><div class="button">閲覧者</div></div>
          <div class="row" @click="updateRole(1)"><div class="button">編集者</div></div>
          <div class="row separate"><div class="button" @click="updateRole(-1)">削除</div></div>
        </div>
      </div>
      <div class="dialog-footer">
        <div class="memo">@tbs.co.jpユーザーのみ利用できます</div>
        <div class="button cancel" @click="close">キャンセル</div>
        <div class="button" @click="update">更新</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'ProjectShare',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup(props) {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()

    // const queries = mixins.getQuery()
    // const project_id: any = route.params.project_id
    const currentProject = {
      project_id: parseInt(props.data.project_id),
    }

    return {
      route,
      router,
      store,
      mixins,
      // queries,
      currentProject,
    }
  },
  data(): {
    editable: any
    share_members: any
    add: any
    context: any
    composing: any
  } {
    return {
      editable: null,
      share_members: null,
      add: null,
      context: null,
      composing: false,
    }
  },
  computed: {
    share_members_(): any {
      const list = Object.assign([], this.share_members)
      list.sort((a: any, b: any) => {
        return a['email'].localeCompare(b['email'], 'ja')
      })
      return list
    }
  },
  watch: {
    //
  },
  async beforeMount() {
    const project = await this.mixins.getProject(this.currentProject)
    this.editable = project
    this.share_members = Object.assign([], project.share_members)
    this.share_members.forEach((member: any) => {
      member.owner = false
    })
    this.share_members.push({
      email: project.user_id,
      user_type: 1,
      owner: true,
    })

    this.share_members.sort((a: any, b: any) => {
      return a['email'].localeCompare(b['email'], 'ja')
    })
  },
  methods: {
    openContext(e: any, item: any) {
      const rect = e.target.closest('.role').getBoundingClientRect()
      console.log(rect)
      this.context = {
        item: item,
        position: rect,
      }
      window.addEventListener('click', this.closeContext)
    },
    closeContext() {
      this.context = null
      window.removeEventListener('click', this.closeContext)
    },
    close() {
      this.$emit('close')
    },
    contextStyle() {
      const x = this.context ? this.context.position.x : 0
      const y = this.context ? this.context.position.y + this.context.position.height : 0
      console.log(x, y)
      return { left: `${x}px`, top: `${y}px` }
    },
    getRoleString(user: any) {
      const string = ['管理者', '編集者', '閲覧者']
      if (user.owner) {
        return '作成者'
      } else {
        return user.user_type === -1 ? '削除' : string[user.user_type]
      }
    },
    updateRole(index: any) {
      this.context.item.user_type = index
    },
    addUser() {
      if (this.composing) { return }

      const user_id = this.add
      let isType: any = false
      let isExist: any = false

      // 半角英数かどうか
      if (user_id.match(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/)) {
        isType = true
      }
      // 英数だった場合
      // 重複チェック
      isExist = this.share_members.every((member: any) => member.email !== user_id)

      // 判定OKなら追加
      if (isType && isExist) {
        this.share_members.push({
          email: user_id,
          user_type: 1,
          owner: false,
        })
      } else {
        let error = ''
        if (!isType) {
          error += '無効なアドレスです\n'
        }
        if (!isExist) {
          error += '既に登録されているアドレスです\n'
        }
        alert(error)
      }
      // 入力欄をクリア
      this.add = null
    },
    async update() {
      // 入力中のものがある
      if (this.add && !confirm('入力中のデータがありますがよろしいですか？')) { return }
      // 変数
      const members = this.share_members
        .filter((member: any) => member.email !== this.editable.user_id && member.user_type !== -1)
        .map((member: any) => {
          return {
            email: member.email,
            user_type: member.user_type,
          }
        })
      const data = {
        project_id: this.editable.project_id,
        members: members,
      }
      const res = await this.mixins.updatesharepro(data)
      if (res) {
        console.log('通ったかのチェック', res)
        let error: any = ''
        res.outputs[0]?.results?.forEach((result: any, i: number) => {
          if (!result) {
            const shares = this.share_members.filter((member: any) => !member.owner)
            error += `${shares[i].email}は無効なアドレスです\n`
          }
        })
        if (error) {
          alert(error)
        } else {
          alert('登録に成功しました')
          this.$emit('update')
        }
      } else {
        console.log('エラー')
      }
    },
  },
})
</script>

<style lang="stylus" scoped>
.dialog
  .wrapper
    width 640px
    height auto
  &-footer
    grid-template auto \/ 1fr auto auto
    .memo
      color crimson
      font-size .8rem
      line-height 1.5

.input
  // &.text
  //   background-color #F4F4F4
  //   border-bottom 1px solid #BABABA
  //   padding 0 .5em
  //   input
  //     display block
  //     width 100%
  //     line-height 50px
  //     &::placeholder
  //       color #aaa
  &.text
    display grid
    grid-template auto / 1fr auto
    background-color #F4F4F4
    border-bottom 1px solid #BABABA
    padding 0 .5em
    input
      display block
      width 100%
      line-height 50px
      &::placeholder
        color #aaa
    .button
      margin auto

.list
  height 250px
  border-bottom 1px solid #bababa
  overflow auto
  .item
    display grid
    grid-template minmax(50px, auto) / 1fr auto
    align-items center
    padding .2em .5em
    font-size .8rem
    line-height 1.5
    border-bottom 1px solid #E3E3E3

    .email
      ellipsis()
    .role
      display grid
      grid-template auto / 3em auto
      align-items center
      padding 0 .3em
      border-radius 2px
      line-height 1.8
      cursor pointer
      .text
        text-align center
      .icon
        color #ccc
      &:hover
        box-shadow 0 0 0 1px alpha(#000, .2)
        .icon
          color $color-text--darken
    &.owner
      .role
        pointer-events none
    &.del
      .email
        opacity .3
//
.context
  position fixed
  z-index 9999
  left 0
  top 0
  background-color #fff
  border-radius 6px
  padding 0 .5em
  min-width 160px
  box-shadow 0 0 10px alpha(#000, .3)
  pointer-events auto
  .row
    padding .3em 0
    & + .separate
      border-top 1px solid #eee
  .button
    display grid
    grid-template auto / auto 1fr
    grid-gap .5em
    align-items center
    color $color-text--darken
    padding .3em
    font-size .7rem
    &:hover
      background-color #eee

</style>
